import http from "@/utils/http";
// 规则管理
export const apiAlarmPush = {
  page(data) {
    return http.request({
      url: "/cemp-bms/alarm-push-rule/page",
      method: "post",
      data,
    });
  },
  delete(id) {
    return http.request({
      url: `/cemp-bms/alarm-push-rule/${id}`,
      method: "delete",
    });
  },
  add(data) {
    return http.request({
      url: "/cemp-bms/alarm-push-rule/add",
      method: "post",
      data,
    });
  },
  edit(data) {
    return http.request({
      url: "/cemp-bms/alarm-push-rule/edit",
      method: "put",
      data,
    });
  },
  detail(id) {
    return http.request({
      url: `/cemp-bms/alarm-push-rule/config/${id}`,
      method: "get",
    });
  },
  ruleEnable(id) {
    return http.request({
      url: `/cemp-bms/alarm-push-rule/enable/${id}`,
      method: "post",
    });
  },
  ruleDisabled(id) {
    return http.request({
      url: `/cemp-bms/alarm-push-rule/disable/${id}`,
      method: "post",
    });
  },
  saveDetail(data) {
    return http.request({
      url: "/cemp-bms/alarm-push-rule/config/save",
      method: "post",
      data,
    });
  },
  projects(){
    return http.request({
      url: "/cemp-bms/project/tenant/project/list",
      method: "get",
    });
  },
  userList(){
    return http.request({
      url: "/cemp-oauth/user/users/tenant/v2",
      method: "get",
    });
  },
};
