<template>
  <BasicModal :title="detail?.id ? '修改推送规则' : '新增推送规则'" width="600px" :visible="visible" :confirmLoading="confirmLoading"
    @ok="ok" @cancel="cancel">
    <div class="project-edit-content">
      <BasicForm class="project-edit-form" ref="formRef" :labelCol="{ span: 5 }" :formItemsMap="formItemsMap"
        v-model:formData="formData"></BasicForm>
    </div>
  </BasicModal>
</template>

<script setup>
import { defineProps, ref, defineEmits, reactive, onMounted } from "vue";
import { message } from "ant-design-vue";
import { apiAlarmPush } from "@/api/IoT/alarmPush.js";

const props = defineProps({
  detail: {
    type: Object,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef = ref(null);

onMounted(() => {
  initFormData();
});

const formItemsMap = reactive({
  name: {
    label: "规则名称",
    prop: "ruleName",
    type: "input",
    required: true,
    requiredMessage: "请输入",
    span: 20,
    maxlength: 100
  },
  ruleDesc: {
    label: "规则描述",
    prop: "description",
    type: "textarea",
    requiredMessage: "请输入",
    span: 20,
    maxlength: 200
  },
});
const formData = reactive({
  ruleName: "",
  description: "",
});
const initFormData = async () => {
  if (!props.detail) return;
  for (const key in formData) {
    formData[key] = props.detail[key];
  }
};


const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef.value.formRef.validateFields();
    const params = {
      id: props.detail?.id,
      ...formData,
    };
    confirmLoading.value = true;
    let func = props.detail?.id ? 'edit' : 'add'
    await apiAlarmPush[func](params);
    message.success(`${props.detail?.id ? '修改成功' : '新增成功'}`);
    emit("update:visible", false);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped></style>
